.people {
  .modal header {
    height: 100%;
    flex-direction: column-reverse;
  }

  .person-name {
    font-weight: normal;
  }

  .person-img {
    margin-bottom: 0.5rem;
  }

  .person-bio {
    padding-top: 32px;
  }
}

@include media-breakpoint-up(lg) {
  .people {
    .modal header {
      height: auto;
      flex-direction: row;
    }

    .modal-dialog {
      display: flex;
      align-items: center;
      min-height: calc(100% - (0.5rem * 2));
    }

    .modal .person-img {
      margin-bottom: 0;
    }

    .modal .person-name {
      font-weight: 600;
    }

    .person-bio p:last-of-type {
      margin-bottom: 0;
    }
  }
}
