.share-icons {
  margin-bottom: 1.25rem;

  .list-inline-item:not(:last-child) {
    margin-right: 1.625rem;
  }

  button {
    background: none;
    border: 0;
  }
}
