.project-layout {
  .project-main {
    margin-bottom: 4.5rem;
  }

  .section-title {
    margin-bottom: 1.875rem;
  }

  .project-description {
    margin-bottom: 36px;
  }
}
