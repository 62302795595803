.lg-footer {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-fixed;
  padding-top: map-get($spacers, 3);
  padding-bottom: map-get($spacers, 3);
  background-color: $white;

  .logo {
    max-width: 8.75rem;
  }
}

@include media-breakpoint-up(md) {
  .lg-footer {
    display: none;
  }
}
