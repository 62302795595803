.journal-feed-main {
  .excerpt {
    font-weight: 300;
    margin-bottom: 3px;
  }
}

@include media-breakpoint-up(md) {
  .journal-feed-main {
    .read-more {
      text-align: right;
    }
  }
}
