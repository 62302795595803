//
// Color system
//

$black:                       #0b0b0b;
$gray-400:                    #a2a2a9;
$gray-500:                    #898989;
$gray-600:                    #6b6b6c;

$text-muted:                  $gray-500;

// Links
//
// Style anchor elements.

$link-color:                  $gray-500;
$link-hover-color:            $black;
$link-hover-decoration:       none;


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-gutter-width:           1.75rem;


// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-size-base:              1rem; // Assumes the browser default, typically `16px`

$h1-font-size:                $font-size-base * 1.25;
$h2-font-size:                $font-size-base * 1.125;
$h3-font-size:                $font-size-base * 1;
$h4-font-size:                $font-size-base * 0.875;
$h5-font-size:                $font-size-base * 0.750;

$headings-font-weight:        600;

$enable-responsive-font-sizes: true;


// Fonts
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:      'Titillium Web', sans-serif;


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem;
$spacers:   (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer * .75,
  4: $spacer,
  5: $spacer * 1.5,
  6: $spacer * 3,
  7: $spacer * 4.5
);


// Components
//
// Define common padding and border radius sizes and more.

$component-active-bg:         $black;


// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-link-hover-bg:            transparent;
$dropdown-border-radius:            0;
