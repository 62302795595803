.lg-sidebar {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
}

.landing-layout .lg-sidebar {
  display: block;
}

.lg-sidebar-sticky {
  position: relative;
  top: 0;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.dropdown-menu[x-placement="top-start"],
.dropdown-menu[x-placement="bottom-start"] {
  margin-left: -5px;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

@include media-breakpoint-up(md) {
  .lg-sidebar {
    display: block;
    padding-left: 2.25rem;
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }

  .lg-sidebar-sticky {
    height: calc(100vh - 72px);
  }

  .logo-desktop {
    padding-left: 2.25rem;
    padding-bottom: 2.25rem;
    max-width: 12.875rem;
  }
}
