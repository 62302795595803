.square-image-grid {
  .tile {
    margin-bottom: map-get($spacers, 3);

    img {
      width: 100%;
    }

    h2 {
      font-weight: normal;
    }
  }
}
