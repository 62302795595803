body {
  padding-top: 1rem;
  padding-bottom: 3.5rem;
}

.lg-content {
  @include make-col-ready();
  @include make-col(12);

  min-height: calc(100vh - 1rem);
}

.landing-layout .lg-content {
  @include make-col(6);
  @include make-col-offset(6);
}

.contact-layout .lg-content {
  display: flex;
  flex-direction: column;
}

@include media-breakpoint-down(sm) {
  .landing-layout {
    padding-bottom: 0;
  }

  .landing-layout .lg-content {
    padding-right: 0;
  }
}

@include media-breakpoint-up(md) {
  body {
    padding: 2.25rem;
  }

  .lg-content,
  .landing-layout .lg-content {
    @include make-col(8);
    @include make-col-offset(4);

    min-height: calc(100vh - 72px);
    padding-right: 0;
  }

  .people-layout .lg-content {
    position: relative;
    justify-content: center;
  }
}
