.projects-layout {
  .project-tile {
    margin-bottom: map-get($spacers, 3);

    img {
      margin-bottom: map-get($spacers, 2);
    }

    h2 {
      font-weight: normal;
    }
  }
}
