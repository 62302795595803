.modal {
  height: auto;
  background-color: white;
}

@include media-breakpoint-up(md) {
  .modal {
    height: 100%;
  }
}

.modal-backdrop {
  display: none;
}

.modal-content {
  border: none;
  border-radius: 0;
}

.modal .close {
  height: 44px;
  padding: 10px;
}

.modal .close svg {
  height: 100%;
}

@include media-breakpoint-down(sm) {
  .modal {
    top: 4rem;
    bottom: 50.58px;
  }

  .modal-dialog {
    margin: 0;
  }
}

@include media-breakpoint-up(md) {
  .people {
    .modal {
      position: absolute;
      overflow-y: hidden;
    }

    .modal-dialog {
      margin-top: 0;
      margin-left: 0;
      margin-bottom: 0;
      max-width: 90%;
    }
  }
}
