.lg-sidebar {
  .nav-link {
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
    font-weight: 600;
    color: black;
  }

  .show > .nav-link {
    color: $gray-500;
  }

  .dropdown-menu {
    border: 0;
    background-color: transparent;
    padding: 0.3125rem 0;
  }

  .dropdown-item {
    padding: 0;
  }

  .dropdown-item.active,
  .dropdown-item:active {
    background-color: transparent;
    color: $gray-500;
    text-decoration: underline;
  }
}
