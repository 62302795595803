@include media-breakpoint-up(md) {
  .fancybox-container {
    left: 25%;
    width: 75%;
  }

  .fancybox-container.fancybox-container--full {
    left: 0;
    width: 100%;
  }
}

@include media-breakpoint-down(sm) {
  [data-fancybox-disable-on="mobile"] {
    pointer-events: none;
    cursor: default;
  }
}

.fancybox-bg {
  background-color: $white;
}

.fancybox-is-open .fancybox-bg {
  opacity: 1;
}

.fancybox-caption {
  color: $black;
  background: none;
}

.fancybox-button {
  color: $black;
  background: none;
}

.fancybox-button:hover {
  color: $black;
}
