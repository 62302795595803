.breadcrumbs {
  a {
    display: inline-block;
    font-weight: 600;
  }

  a:nth-of-type(1) {
    color: $gray-400;
  }

  a:nth-of-type(2) {
    color: $gray-500;
  }

  .links-container {
    display: inline-block;
  }

  .links-container a {
    color: $gray-500;
  }
}
