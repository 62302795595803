@keyframes panning {
  0% {
    background-position: 0% 50%;
  }

  100% {
    background-position: 100% 50%;
  }
}

.panning-background-holder {
  width: 100%;
  background-position: 0% 50%;
  animation: panning 50s infinite linear;
  animation-direction: alternate;
  height: 24rem;
  background-size: cover;
}

@include media-breakpoint-up(md) {
  .panning-background-holder {
    height: 60vh;
  }
}

@media (orientation: landscape) {
  .panning-background-holder {
    background-size: 200%;
  }
}

@media (orientation: portrait) {
  .panning-background-holder {
    background-size: cover;
  }
}
