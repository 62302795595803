.carousel--full-height .carousel-inner,
.carousel--half-height .carousel-inner {
  height: 50vh;
}

.carousel--full-height .carousel-item,
.carousel--half-height .carousel-item {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel--full-height img,
.carousel--half-height img {
  max-height: calc(50vh - 1rem);
  max-width: 100%;
}

.carousel-control-prev,
.carousel-control-next {
  width: 30%;
}

.carousel-control-prev:hover {
  cursor: url("/assets/images/prev_arrow.svg");
}

.carousel-control-next:hover {
  cursor: url("/assets/images/next_arrow.svg");
}

.carousel-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    color: $black;
  }

  .slide-number {
    font-size: 0.875rem;
  }
}

.carousel-controls {
  display: flex;

  a {
    display: flex;
    height: 1.5rem;
    padding: 6px;
  }

  a.fullscreen-icon {
    padding: 3px;
  }

  a img {
    height: 100%;
  }
}

@media (min-width: 768px) and (orientation: landscape) {
  .carousel--full-height {
    margin-bottom: 0;
  }

  .carousel--full-height .carousel-inner {
    height: calc(95vh - 6.25rem);
  }

  .carousel--full-height figure {
    width: 100%;
  }

  .carousel--full-height img {
    max-height: calc(100vh - 2rem - 1.5rem - 1.125rem - 3rem);
    margin: auto;
    margin-bottom: 0;
  }
}

@include media-breakpoint-down(xs) {
  .carousel {
    margin-left: -($grid-gutter-width / 2);
    margin-right: -($grid-gutter-width / 2);
  }

  .carousel figcaption {
    margin-left: $grid-gutter-width / 2;
    margin-right: $grid-gutter-width / 2;
  }
}
