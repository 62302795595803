/**
 * CONTENTS
 *
 * SETTINGS
 * Variables.............Globally-available variables and config.
 *
 * BOOTSTRAP
 * bootstrap.............Import Bootstrap library
 *
 * BASE
 * Typography............Type styles.
 *
 * COMPONENTS
 * Breadcrumbs
 * Carousel
 * Content
 * Footer
 * Journal nav
 * Modal
 * Nav
 * Panning background
 * Share icons
 * Sidebar
 * Square image grid
 *
 * PAGES
 * Contact
 * Journal entry
 * Journal
 * People
 * Project
 * Projects
*/

@import "reset";
@import "variables";

@import "bootstrap/scss/bootstrap";

@import "mixins/fluid-type";

@import "content/typography";

@import "components/breadcrumbs";
@import "components/carousel";
@import "components/content";
@import "components/fancybox";
@import "components/footer";
@import "components/journal-nav";
@import "components/modal";
@import "components/navs";
@import "components/panning-background";
@import "components/share-icons";
@import "components/sidebar";
@import "components/social-media-icons-horizontal";
@import "components/square-image-grid";

@import "layouts/journal";
@import "layouts/journal-entry";
@import "layouts/people";
@import "layouts/project";
@import "layouts/projects";
