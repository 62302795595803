.journal-entry-layout {
  h1 {
    text-decoration: underline;
    margin-bottom: 0;
  }

  dt {
    font-weight: 600;
  }

  @include media-breakpoint-up(md) {
    h1 {
      text-decoration: none;
    }
  }
}
