html {
  @include fluid-type(font-size, 1440px, 1920px, 16px, 20px);
}

a:active {
  text-decoration: underline;
}

.contact-layout .credits {
  strong, a {
    font-size: 0.75rem;
  }
}
